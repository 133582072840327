import * as React from 'react';
import { object, ref, string, boolean } from 'yup';
import TitleContainer from '../TitleContainer/TitleContainer';
import SettingsFieldRow from 'bundles/App/pages/Account/Settings/SettingsFieldRow';
import Button from 'styleguide/components/Button/Button';
import Footer from 'styleguide/components/Footer/Footer';
import { Status } from 'libs/utils/api/types';
import { updateSettings } from 'api/account/settings';
import { Formik, Form, FormikValues, FormikErrors } from 'formik';
import NotificationsForm from 'styleguide/components/Formik/NotificationsForm/NotificationsForm';
import Loader from 'styleguide/components/Loader/Loader';
import ErrorMessage from 'styleguide/components/forms/ErrorMesssage/ErrorMessage';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import UserContext from 'contexts/UserContextContainer/UserContext';

const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

const AccountSettingsFormSchema = object().shape({
  email: string()
    .strict()
    .email('Please enter a valid email.')
    .trim('Email cannot begin or end with spaces.')
    .required('Email is required.'),
  smsPhone: string().when('sendSmsNotifications', {
    is: value => !!value,
    then: schema =>
      schema
        .strict()
        .typeError('Phone number required')
        .trim('Phone number cannot begin or end with spaces.')
        .required('Phone number is required.')
        .matches(phoneRegex, 'Phone number is not valid.'),
    otherwise: schema => schema.nullable(),
  }),
  firstName: string()
    .strict()
    .trim('Name cannot begin or end with spaces.')
    .required('First name is required.'),
  lastName: string()
    .strict()
    .trim('Name cannot begin or end with spaces.')
    .required('Last name is required.'),
  sendSmsNotifications: boolean(),
  password: string()
    .ensure()
    .test('password-test', 'Password must be at least 8 characters.', value =>
      value === '' ? true : value.length >= 8,
    ),
  confirmation: string()
    .ensure()
    .when('password', {
      is: value => value === '',
      then: schema => schema.oneOf([''], 'Your password and confirmation do not match.'),
      otherwise: schema => schema.oneOf([ref('password')], 'Your password and confirmation do not match.'),
    }),
});

const SettingsPage = () => {
  const { currentUser, logIn } = React.useContext(UserContext);

  const onSubmit = (
    values: FormikValues,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
  ) => {
    const userSettings = {
      ...(!!values.password && { password: values.password }),
      id: currentUser.id,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      userNotificationSettingsAttributes: {
        sendSmsNotifications: !!values.sendSmsNotifications,
        smsPhone: values.smsPhone,
      },
    };

    updateSettings(userSettings).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        logIn(res.payload.user);
      }
    });
  };

  const buildInitialValues = () => ({
    firstName: currentUser.firstName ? currentUser.firstName : '',
    lastName: currentUser.lastName ? currentUser.lastName : '',
    email: currentUser.email,
    smsPhone: currentUser.smsPhone,
    sendSmsNotifications: !!currentUser.smsEnabled,
    password: null,
    confirmation: null,
  });

  return (
    <>
      <TitleContainer title="Account Settings" />
      <div className="mx-auto mt-8 max-w-2xl rounded-xl border border-gray-100 p-4 shadow-xl">
        <Formik
          initialValues={buildInitialValues()}
          onSubmit={(values, { setStatus, setSubmitting, setErrors }) => {
            onSubmit(values, setStatus, setSubmitting, setErrors);
          }}
          validationSchema={AccountSettingsFormSchema}
        >
          {formikProps => (
            <Form>
              <div>
                <h6 className="font-hvBold text-xl">Personal Information</h6>
                <div>
                  <SettingsFieldRow<ReturnType<typeof buildInitialValues>>
                    first
                    fieldDisplayTitle="Name"
                    fieldDisplayValue={
                      currentUser.firstName
                        ? `${currentUser.firstName} ${currentUser.lastName}`
                        : 'Click edit to set name'
                    }
                    fields={[
                      { title: 'First Name', name: 'firstName' },
                      { title: 'Last Name', name: 'lastName' },
                    ]}
                    formikProps={formikProps}
                  />
                  <SettingsFieldRow<ReturnType<typeof buildInitialValues>>
                    fieldDisplayTitle="Email Address"
                    fieldDisplayValue={currentUser.email}
                    fields={[{ title: 'Email Address', name: 'email' }]}
                    formikProps={formikProps}
                  />
                  <SettingsFieldRow<ReturnType<typeof buildInitialValues>>
                    fieldDisplayTitle="Password"
                    fieldDisplayValue="*************"
                    fields={[
                      { title: 'New password', name: 'password' },
                      { title: 'Confirm new password', name: 'confirmation' },
                    ]}
                    formikProps={formikProps}
                  />
                </div>
              </div>
              <div className="mt-4">
                <NotificationsForm prefix="" />
              </div>
              <div className="flex justify-center">
                {formikProps.isSubmitting ? (
                  <Loader />
                ) : (
                  <Button type="button" onClick={() => formikProps.submitForm()} color="orange">
                    Update Changes
                  </Button>
                )}
                {!!formikProps.status && <ErrorMessage message={formikProps.status.title} />}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </>
  );
};

export default SettingsPage;
